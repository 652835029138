body {
    font-family: sans-serif;
    background: #171819;
  }
  
  .grid-item {
    background: #212124;
    border: 1px solid #141414;
    display: flex;
    flex-direction: column;
  }
  
  .grid-item__title {
    font-size: 14px;
    color: #fff;
    padding: 4px 0;
    text-align: center;
  }
  .grid-item__title:hover {
    cursor: move;
  }
  .grid-item__title .panel {
    box-shadow: none;
    border: none;
  }
  .grid-item__title .panel:focus {
    outline: none;
  }
  .grid-item__title .panel-heading {
    padding: 3px 0;
    background: #171819;
    border: none;
    color: rgba(255, 255, 255, 0.7);
  }
  .grid-item__title .panel-body {
    padding: 0 0 0 15px;
    background: #171819;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .grid-item__graph {
    flex: 1;
  }
  
  .type-item {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
    padding: 3px 0;
  }
  .type-item:hover {
    color: #fff;
  }
  
  .type-item.selected {
    color: #fff;
  }
  
  .data-edit__error {
    color: rgba(255, 0, 0, 0.7);
    font-size: 12px;
    padding: 3px 0;
  }
  
  .recharts-text {
    font-size: 12px;
  }
  
  .detail__info {
    color: #fff;
    padding: 20px;
  }
  
  .detail__edit {
    padding: 20px;
  }
  
  .detail__info > div {
    padding: 5px 0;
  }
  
  label {
    color: #fff;
    display: block;
  }
  .dropdown > .btn-default,
  .dropdown.open > .btn-default {
    background: #171819;
    border: 1px solid #141414;
    color: #fff;
  }
  .dropdown > .btn-default:hover,
  .dropdown > .btn-default:focus .dropdown.open > .btn-default:hover,
  .dropdown.open > .btn-default:focus {
    background: #171819;
    border: 1px solid #141414;
    color: #fff;
    outline: none;
  }
  
  .dropdown-menu {
    border: 1px solid #141414;
    background: #171819;
    color: #fff;
  }
  .dropdown-menu > li > a {
    color: rgba(255, 255, 255, 0.7);
  }
  .dropdown-menu > li > a:hover {
    background: #171819;
    color: #fff;
  }
  
  .react-grid-item > .react-resizable-handle::after {
    border-right-color: rgba(255, 255, 255, 0.5);
    border-bottom-color: rgba(255, 255, 255, 0.5);
  }
  
  .modal-body {
    padding: 0;
  }
  
  .data-edit {
    position: relative;
  }
  .data-edit__update {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 10;
  }
  
  .detail-page__title,
  .edit-page__title {
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    color: #fff;
    text-align: center;
  }
  
  .go-back {
    position: fixed;
    right: 20px;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    top: 20px;
    z-index: 10;
  }
  
  .go-back:hover {
    color: #fff;
    cursor: pointer;
  }
  

  .react-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
  .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
  }
  .react-grid-item.cssTransforms {
    transition-property: transform;
  }
  .react-grid-item.resizing {
    z-index: 1;
    will-change: width, height;
  }
  
  .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
  }
  
  .react-grid-item.react-grid-placeholder {
    background: red;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  
  .react-grid-item > .react-resizable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 0;
    right: 0;
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=");
    background-position: bottom right;
    padding: 0 3px 3px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
  }
  

  body {
    background: white;
    padding: 20px;
    overflow: scroll;
  }
  #content {
    width: 100%;
  }
  .react-grid-layout {
    background: white;
  }
  .layoutJSON {
    background: #ddd;
    border: 1px solid black;
    margin-top: 10px;
    padding: 10px;
  }
  .columns {
    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
  }
  .react-grid-item {
    box-sizing: border-box;
  }
  .react-grid-item:not(.react-grid-placeholder) {
    background: white;
    border: 1px solid black;
  }
  .react-grid-item.resizing {
    opacity: 0.9;
  }
  .react-grid-item.static {
    background: #cce;
  }
  .react-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 24px;
  }
  .react-grid-item .minMax {
    font-size: 12px;
  }
  .react-grid-item .add {
    cursor: pointer;
  }
  .react-grid-dragHandleExample {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  li b {
    font-size: 19px;
    line-height: 14px;
  }
  
  .chart-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    min-width: 0;
    max-width: 100%;
  }

.container__grid {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: white ;
    align-items: center;
    display: flex;
    justify-content: center;
  }

.item__map {
  position: absolute;
  height: 100%;
  width: 100%;
}  

.item__video_refresh{
  margin: 0 auto;
  position: absolute;
  display: flex;
  width:100px;
  justify-content: center;
  z-index: 10;
}

.item__title {
    position: absolute;
    text-align: center;
    display: flex;
    top: 0%;
    left: 0%;
    margin-top: 10px;
    margin-left: 10px;
    width:10px;
    height:10px;
    font-size: large;
    border: 1px solid;
    border-color:#C0C0C0;
    padding: 2px;
    z-index: 9;
}
